import { CheckBox } from "@pixi/ui";
import { Container, Sprite } from "pixi.js";

export class Checkbox extends Container {
	view: CheckBox;

	constructor(text: string, onChange: (value: boolean) => void) {
		super();
		this.view = new CheckBox({
			style: {
				unchecked: Sprite.from("checkbox-false"),
				checked: Sprite.from("checkbox-true"),
			},
			text: text,
		});
		this.view.onChange.connect((value) => onChange(!!value));
		this.addChild(this.view);
	}
}
