import { GameManager } from "@/managers/GameManager";
import { Container, Sprite, Text, TextStyle } from "pixi.js";
import { Logo } from "./containers/Logo";
import { RowLayout } from "./containers/RowLayout";
import { Button } from "./containers/ui/Button";
import { IScene } from "./IScene";

export abstract class GameOverScene extends Container implements IScene {
	logo: Logo;

	header: RowLayout;
	teamLogo: Sprite;
	teamLabel: Text;
	msgLabel: Text;
	btn: Button;

	protected readonly _innerPadding = GameManager.sceneManager.height * 0.1;
	protected readonly _innerMargin = 64;

	constructor(teamLogo: Sprite, message: string, onLeave: () => void) {
		super();

		this.logo = new Logo();
		this.logo.position.set(
			GameManager.sceneManager.width / 2 - this.logo.width / 2,
			GameManager.sceneManager.height / 2 - this.logo.height / 2
		);

		this.teamLogo = teamLogo;
		this.teamLabel = new Text(
			"wins !",
			new TextStyle({
				fontSize: 48,
			})
		);
		this.msgLabel = new Text(message);

		this.btn = new Button({
			width: 196,
			height: 72,
			fontSize: 24,
			text: "Return to lobby",
			onTap: onLeave,
		});

		this.header = new RowLayout({
			maxWidth: GameManager.sceneManager.width * 0.4,
			elementsMargin: 36,
			vertPadding: 36,
			horPadding: 36,
		});
		this.header.addChild(this.teamLogo, this.teamLabel);
		this.addChild(this.logo, this.header, this.msgLabel, this.btn);
		this.updateLayout();
	}

	updateLayout() {
		this.teamLabel.y = this.teamLogo.height / 2 + this.teamLabel.height / 2;
		this.header.position.set(GameManager.sceneManager.width / 2 - this.header.width / 2, this._innerPadding);
		this.msgLabel.position.set(
			this.logo.x + this.logo.width / 2 - this.msgLabel.width / 2,
			this.logo.y + this.logo.height + this._innerMargin
		);
		this.btn.position.set(
			this.header.x + this.header.width / 2 - this.btn.width / 2,
			GameManager.sceneManager.height * 0.8
		);
	}

	update(framesPassed: number): void {}
}

export class TeamMoriartyWins extends GameOverScene {
	constructor(message: string, onLeave: () => void) {
		super(Sprite.from("team-moriarty"), message, onLeave);
	}
}

export class TeamSherlockWins extends GameOverScene {
	constructor(message: string, onLeave: () => void) {
		super(Sprite.from("team-sherlock"), message, onLeave);
	}
}
