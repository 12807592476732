import { Easing, Tween } from "@tweenjs/tween.js";
import { Sprite, Texture } from "pixi.js";
import type { CardState } from "server/state/CardState";
import { ImprovedContainer, StatefulContainer } from "./Containers";

export class Card extends ImprovedContainer implements StatefulContainer<CardState> {
	textures: { front: Texture; back: Texture };
	sprite: Sprite;

	// Protected properties
	protected _id!: number;
	protected _revealed!: CardState["revealed"];
	protected _highlighted!: boolean;

	// Events
	onClick?: (id: number) => void;
	onHoverIn?: (id: number) => void;
	onHoverOut?: (id: number) => void;

	constructor(state: CardState) {
		super();
		// Get textures from card types (presume Assets are already loaded)
		this.textures = {
			front: Texture.from(state.type),
			back: Texture.from("Back-face"),
		};
		this.sprite = new Sprite(this.textures.back);
		// Center sprite pivot and center sprite in this container
		this.sprite.pivot.set(this.sprite.width / 2, this.sprite.height / 2);
		this.sprite.position.set(this.sprite.width / 2, this.sprite.height / 2);
		this.addChild(this.sprite);
		this.updateState(state);

		// Setup interaction events
		this.on("pointertap", () => this.onClick?.(this._id));
		this.on("mouseenter", () => this.onHoverIn?.(this._id));
		this.on("mouseleave", () => this.onHoverOut?.(this._id));
		this.interactive = true;
	}

	get isShown() {
		return this._revealed === "locally" || this._revealed === "publicly";
	}

	get revealed() {
		return this._revealed;
	}

	set revealed(value: CardState["revealed"]) {
		const previous = this._revealed;
		this._revealed = value;
		if (previous !== value) this.flip(value === "no" ? "hide" : "reveal");
	}

	get highlighted() {
		return this._highlighted;
	}

	set highlighted(value: boolean) {
		this._highlighted = value;
		if (value) new Tween(this.position).to({ y: -40 }, 200).easing(Easing.Quadratic.Out).start();
		else new Tween(this.position).to({ y: 0 }, 200).easing(Easing.Quadratic.Out).start();
	}

	updateState(state: CardState): void {
		// Update some local state variables
		this._id = state.id;
		this.revealed = state.revealed;
		this.highlighted = state.highlighted;
	}

	protected flip(direction: "hide" | "reveal") {
		const newTexture = direction === "reveal" ? this.textures.front : this.textures.back;
		const target = direction === "reveal" ? -1 : 1;

		// Flip card sprite on X-axis by scaling it to the opposite
		new Tween(this.sprite.scale)
			.to({ x: target }, 750)
			.easing(Easing.Quadratic.Out)
			.onUpdate((point) => {
				// Change texture at mid-course
				if ((target === -1 && point.x < 0) || (target === 1 && point.x > 0)) this.sprite.texture = newTexture;
			})
			.start();
	}
}
