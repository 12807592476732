import { filter, Schema, type } from "@colyseus/schema";
import { PlayerId } from "./PlayerState";

export enum TeamMoriarty {
	Moriarty = "Moriarty",
	Adler = "Adler",
	Sbire = "Sbire",
}

export enum TeamSherlock {
	Sherlock = "Sherlock",
	Watson = "Watson",
	Lestrade = "Lestrade",
	Hudson = "Hudson",
	MissWatson = "MissWatson",
}

export const RoleType = { ...TeamMoriarty, ...TeamSherlock };
export type RoleType = TeamMoriarty | TeamSherlock;

export class RoleState extends Schema {
	@filter(function (this: RoleState, client, value: RoleState["type"], root) {
		// Send role type to client only to the concerned player. 
		// Other players will receive "undefined".
		// Prevents cheating and removes the task of showing or not the avatar from client-side code
		return client.sessionId === this.affectedTo;
	})
	// HACK: Hardcode enum underlying type to enable serialization
	@type("string")
	type: RoleType;

	// HACK: Hardcode underlying type to enable serialization
	@type("string")
	affectedTo!: PlayerId;

	constructor(type: RoleType) {
		super();
		this.type = type;
	}
}
