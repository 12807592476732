import { Sprite, Text } from "pixi.js";
import { CardType } from "server/state/CardState";
import { ImprovedContainer, StatefulContainer } from "./Containers";

export class DefuseCounter extends ImprovedContainer implements StatefulContainer<number> {
	tachometer: Sprite;
	wire: Sprite;
	text: Text;

	protected readonly _innerMargin = 16;

	constructor(state: number) {
		super();
		// Init sprites
		this.tachometer = Sprite.from("tachometer");
		this.wire = Sprite.from(CardType.Fuse);
		this.text = new Text(state, {
			fill: "#a7613d",
			fontFamily: "Times New Roman",
			fontSize: 38,
			fontWeight: "bold",
		});

		// Position sprites
		this.wire.scale.set(0.35, 0.35);
		(this.wire.x = this.tachometer.x + this.tachometer.width / 2 + this.wire.width / 2 + this._innerMargin),
			(this.tachometer.y = this.wire.height / 2 - this.tachometer.height / 2);

		this.text.pivot.set(this.text.width / 2, this.text.height / 2);
		this.text.position.set(this.tachometer.width / 2, this.tachometer.height / 2 + 23);
		this.addChild(this.tachometer, this.wire, this.text);

		this.updateState(state);
	}

	updateState(state: number): void {
		this.text.text = state;
		this.visible = state > 0;
	}
}
