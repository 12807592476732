import { ScrollBox, type ScrollBoxOptions } from "@pixi/ui";
import { Container } from "pixi.js";

export class ItemList<Item extends Container> extends Container {
	protected _view: ScrollBox;
	protected _emptyLabel: Container;

	get items() {
		return this._view.items;
	}

	constructor(options: ScrollBoxOptions, emptyLabel: Container) {
		super();
		this._view = new ScrollBox({
			type: "vertical",
			elementsMargin: 6,
			vertPadding: 18,
			...options,
		});
		this._emptyLabel = emptyLabel;
		this.updateLayout();
	}

	addItem(...items: Item[]) {
		if (items.length > 0) this._view.addItem(...items);
		this.updateLayout();
	}

	removeItem(item: Item) {
		const idx = this._view.items.findIndex((e) => e === item);
		if (idx !== -1) this._view.removeItem(idx);
		this.updateLayout();
	}

	clearItems() {
		for (let i = 0; i < this._view.items.length; i++) {
			this._view.removeItem(i);
		}
		this.updateLayout();
	}

	protected updateLayout() {
		// If no item, display the placeholder component
		// Otherwise, show the list
		if (this.items.length > 0) {
			this.removeChild(this._emptyLabel);
			this.addChild(this._view);
		} else {
			this.removeChild(this._view);
			this.addChild(this._emptyLabel);
		}
	}
}
