import { Container, Graphics, Sprite } from "pixi.js";
import type { CardState } from "server/state/CardState";
import { zip } from "server/utils/common";
import { Card } from "./Card";
import { ImprovedContainer, StatefulContainer } from "./Containers";

export class CardHand extends ImprovedContainer implements StatefulContainer<CardState[]> {
	cards: Card[] = [];

	protected _style: "linear" | "circular" = "linear";
	protected _isMine: boolean;
	protected _recallFeature = {
		enabled: false,
		overlay: new RecallOverlay(),
		active: false,
		cardsShowStateBackup: new Array<CardState["revealed"]>(),
	};

	constructor(state: CardState[], isMine: boolean) {
		super();
		this._isMine = isMine;
		this.updateState(state);

		// Additional logic if this hand belongs to the local player
		if (isMine) {
			this._recallFeature.enabled = true;
			this.interactive = true;
			this.cursor = "pointer";
			this.on("pointerdown", () => this.showFromOverlay());
			this.on("pointerup", () => this.hideFromOverlay());
			this.on("pointerupoutside", () => this.hideFromOverlay());
			this.on("mouseenter", () => this.showOverlay());
			this.on("mouseleave", () => this.hideOverlay());
		}
	}

	get isShown() {
		return this.cards.every((card) => card.isShown);
	}

	get style() {
		return this._style;
	}

	set style(value: typeof this._style) {
		this._style = value;
		this.updateLayout();
	}

	updateState(state: CardState[]): void {
		if (state.length !== this.cards.length) {
			// If cards have been added/removed, recreate them all with the new state
			this.removeChildren();
			this.cards = state.map((cardState) => new Card(cardState));
			if (this.cards.length > 0) this.addChild(...this.cards);
		} else {
			// Otherwise, update existing cards with the new state
			zip(this.cards, state).forEach(([card, newState]) => {
				if (card && newState) card?.updateState(newState);
			});
		}

		this.updateLayout();
	}

	updateLayout() {
		this._style === "circular" ? this.layInCircle() : this.layInLine();
		this._recallFeature.overlay.updateLayout(this.width, this.height);
	}

	show() {
		this.cards.forEach((card) => (card.revealed = "locally"));
	}

	hide() {
		this.cards.forEach((card) => (card.revealed = "no"));
	}

	protected showOverlay() {
		if (this._recallFeature.enabled && !this.isShown) this.addChild(this._recallFeature.overlay);
	}

	protected hideOverlay() {
		if (this._recallFeature.enabled) this.removeChild(this._recallFeature.overlay);
	}

	protected showFromOverlay() {
		if (this._recallFeature.enabled && !this.isShown) {
			this.hideOverlay();
			// Backup cards show state to restore it later
			this._recallFeature.cardsShowStateBackup = this.cards.map((card) => card.revealed);
			// Reveal all cards (does not use this.show() to separate the recall feature from the rest)
			this.cards.forEach((card) => (card.revealed = "locally"));
			this._recallFeature.active = true;
		}
	}

	protected hideFromOverlay() {
		if (this._recallFeature.enabled && this._recallFeature.active) {
			// Restore previous card show state
			zip(this.cards, this._recallFeature.cardsShowStateBackup).forEach(([card, prevState]) => {
				if (card && prevState !== undefined) card.revealed = prevState;
			});
			this._recallFeature.active = false;
		}
	}

	protected layInLine() {
		let previous: Card;
		this.cards.forEach((card) => {
			const x = previous?.x ?? 0;
			const w = previous?.width ?? 0;
			card.pivot.set(0, 0); // Reset pivot to top-left corner
			card.x = x + w / 2;
			previous = card;
		});
	}

	protected layInCircle() {
		const minAngle = -40;
		const maxAngle = 40;
		const step = (Math.abs(minAngle) + Math.abs(maxAngle)) / this.cards.length;
		for (let i = 0; i < this.cards.length; i++) {
			const card = this.cards[i];
			card.pivot.set(20, card.height); // Move pivot to bottom-left corner to rotate around that point
			card.angle = minAngle + i * step;
		}
	}
}

class RecallOverlay extends Container {
	overlay: Graphics;
	eyeIcon: Sprite;

	protected _clicked!: boolean;

	constructor() {
		super();
		this.eyeIcon = Sprite.from("eye");
		this.overlay = new Graphics().beginFill(0xffffff).drawRect(0, 0, 1, 1).endFill();
		this.alpha = 0.3;
		this.updateLayout(this.width, this.height);
	}

	updateLayout(width: number, height: number) {
		// Remove children to scale overlay to requested width/height
		// without scaling eyeIcon in the process
		this.removeChildren();
		this.overlay.width = width;
		this.overlay.height = height;
		this.addChild(this.overlay, this.eyeIcon);

		// Center icon on overlay
		this.eyeIcon.pivot.set(this.eyeIcon.width / 2, this.eyeIcon.height / 2);
		this.eyeIcon.position.set(this.width / 2, this.height / 2);
	}
}
