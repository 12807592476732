import { Room } from "colyseus.js";
import { GameManager } from "@/managers/GameManager";
import { WaitingScene } from "@/scenes/WaitingScene";
import { PlayerId, PlayerState } from "server/state/PlayerState";
import { ClientRoom } from "./ClientRoom";
import { ClientMessages, ErrorCodes, ServerMessages } from "server/messages/Messages";

export class WaitingRoom extends ClientRoom<WaitingScene> {
	constructor(room: Room) {
		super(room, new WaitingScene());
	}

	protected setupMessageCallbacks(): void {
		this.room.onMessage(ServerMessages.GameStartCountdown, (message) => {
			this.scene.updateStartCountdown(message);
		});

		this.room.onMessage(ServerMessages.GameStartCountdownCancelled, (message) => {
			this.scene.hideLoader();
		});

		this.room.onMessage(ServerMessages.StartGame, (message) => {
			GameManager.startGame(this.room);
		});
	}

	protected setupStateCallbacks(): void {
		// Update scene on each state update
		this.room.onStateChange((state) => this.scene.updateState(state));

		// Add player to scene
		this.room.state.players.onAdd((player: PlayerState, key: PlayerId) => {
			this.scene.addPlayer(key, player);
		}, true);

		// Remove player from scene
		this.room.state.players.onRemove((player: PlayerState, key: PlayerId) => {
			this.scene.removePlayer(key);
		}, true);
	}

	protected onLeave(code: number): void {
		console.log("Left waiting room");
	}

	protected onError(code: number, message?: string): void {
		// Disable ready button if player name error
		if (code === ErrorCodes.PlayerNameAlreadyUsed) {
			this.scene.setReadyButtonActivation(false);
		} else {
			super.onError(code, message);
		}
	}

	setReady(value: boolean) {
		// Disable player name change if ready is true
		this.scene.setPlayerNameInputActivation(!value);
		this.room.send(ClientMessages.SetReady, value);
	}

	changePlayerName(value: string) {
		// Reset ready button activation while waiting for server to approve name change
		// +
		// Disable ready button if name is empty
		const name = value.trim();
		if (name) {
			this.scene.setReadyButtonActivation(true);
			this.room.send(ClientMessages.ChangePlayerName, name);
		} else this.scene.setReadyButtonActivation(false);
	}
}
