import { PlayerId } from "../state/PlayerState";

export enum ErrorCodes {
	PlayerNameEmpty,
	PlayerNameAlreadyUsed,
	GameAbortedAfterPlayerLeft,
	GameRuleViolated,
}

export enum ServerMessages {
	// Lobby room messages
	TotalPlayerCountUpdate = "total-player-count-update",
	// Waiting room messages
	GameStartCountdown = "game-start-countdown",
	GameStartCountdownCancelled = "game-start-countdown-cancelled",
	StartGame = "start-game",
	// Game messages
	RoundPause = "round-pause",
	GameOver = "game-over",
}

export enum ClientMessages {
	// Waiting room messages
	SetReady = "set-ready",
	ChangePlayerName = "change-player-name",
	// Game messages
	HighlightCard = "highlight-card",
	CutCable = "cut-cable",
}

export enum RoundPauseReason {
	RoundWrapStart,
	RoundWrapEnd,
	ShuffleStart,
	ShuffleEnd,
	ShowHand,
	HideHand,
}

export enum GameOverReason {
	BombTriggered,
	BombNotDefused,
	BombDefused,
}

export interface GameOverData {
	reason: GameOverReason;
	blame?: PlayerId;
	// TODO add game stats ?
}
