import { GameManager } from "@/managers/GameManager";
import type { GameState } from "server/state/GameState";
import type { PlayerId, PlayerState } from "server/state/PlayerState";
import { zip } from "server/utils/common";
import { ImprovedContainer, StatefulContainer } from "./containers/Containers";
import { EditableIdentity, Identity } from "./containers/Identity";
import { Loader } from "./containers/Loader";
import { Logo } from "./containers/Logo";
import { RowLayout } from "./containers/RowLayout";
import { Button } from "./containers/ui/Button";
import { Checkbox } from "./containers/ui/Checkbox";
import { IScene } from "./IScene";

class LocalPlayerSlot extends ImprovedContainer implements StatefulContainer<PlayerState> {
	identity: EditableIdentity;
	leaveBtn: Button;
	readyCheckbox: Checkbox;

	protected readonly _innerMargin = 128;

	constructor(state: PlayerState) {
		super();
		this.identity = new EditableIdentity(state, (value) => GameManager.changePlayerName(value));
		this.leaveBtn = new Button({
			width: 196,
			height: 72,
			fontSize: 24,
			text: "Return to lobby",
			onTap: () => GameManager.returnToLobby(),
		});

		this.readyCheckbox = new Checkbox("Ready ?", (value: boolean) => GameManager.setReady(value));

		this.addChild(this.identity, this.leaveBtn, this.readyCheckbox);
		this.updateLayout();
	}

	updateState(state: PlayerState): void {
		this.identity.updateState(state);
	}

	updateLayout() {
		this.leaveBtn.position.set(0, this.identity.y + this.identity.height / 2 - this.leaveBtn.height / 2);
		this.identity.position.set(this.leaveBtn.x + this.leaveBtn.width + this._innerMargin, 0);
		this.readyCheckbox.position.set(
			this.identity.x + this.identity.width + this._innerMargin,
			this.identity.y + this.identity.height / 2 - this.readyCheckbox.height / 2
		);
	}
}

export class WaitingScene extends ImprovedContainer implements IScene, StatefulContainer<GameState> {
	otherPlayersIdentities: Map<PlayerId, Identity> = new Map();
	localPlayerSlot!: LocalPlayerSlot;
	logo: Logo;

	topRow: RowLayout;

	protected _loader: Loader;
	protected readonly _innerPadding = 24;

	constructor() {
		super();
		this.logo = new Logo(0.75);
		this.topRow = new RowLayout({ maxWidth: GameManager.sceneManager.width * 0.8 });
		this._loader = new Loader();

		this.logo.position.set(
			GameManager.sceneManager.width / 2 - this.logo.width / 2,
			GameManager.sceneManager.height / 2 - this.logo.height / 2
		);

		this.addChild(this.logo, this.topRow, this._loader);
	}

	addPlayer(key: PlayerId, state: PlayerState) {
		if (key === GameManager.clientID) {
			this.localPlayerSlot = new LocalPlayerSlot(state);
			this.addChild(this.localPlayerSlot);
			this.localPlayerSlot.position.set(
				GameManager.sceneManager.width / 2 - this.localPlayerSlot.width / 2,
				this.logo.y + this.logo.height + this.localPlayerSlot.height / 2 + this._innerPadding
			);
		} else {
			const identity = new Identity(state);
			this.otherPlayersIdentities.set(key, identity);
			this.addChild(identity);
			this.updateLayout();
		}
	}

	removePlayer(key: PlayerId) {
		const identity = this.otherPlayersIdentities.get(key);
		if (identity) {
			this.removeChild(identity);
			this.otherPlayersIdentities.delete(key);
			this.updateLayout();
		}
	}

	updateState(state: GameState) {
		// Get state of other players
		const statePlayers = [...state.players?.keys()]
			.filter((key) => key !== GameManager.clientID)
			.map((key) => state.players.get(key));

		const viewIdentities = [...this.otherPlayersIdentities.values()];
		// Update each identity view based on its respective state
		zip(viewIdentities, statePlayers).forEach(([view, state]) => {
			// Safeguard in case there's not the same number of views and states
			if (state && view) view.updateState(state);
		});
		const localPlayerState = state.players.get(GameManager.clientID ?? "");
		if (localPlayerState) this.localPlayerSlot?.updateState(localPlayerState);
	}

	updateLayout() {
		// Top row layout update
		const identities = [...this.otherPlayersIdentities.values()];
		this.topRow.removeChildren();

		if (identities.length > 0) this.topRow.addChild(...identities);

		this.topRow.position.set(
			GameManager.sceneManager.width / 2 - this.topRow.width / 2,
			this.logo.y / 2 - this.topRow.height / 2
		);
	}

	showLoader(message?: string | number) {
		this._loader.message = message;
		this._loader.position.set(
			this.logo.x + this.logo.width / 2 - this._loader.width / 2,
			this.logo.y + this.logo.height + this._innerPadding
		);
		this._loader.start();
	}

	hideLoader() {
		this._loader.stop();
	}

	updatePlayerName(value: string) {
		this.localPlayerSlot.identity.setUsername(value);
	}

	setPlayerNameInputActivation(value: boolean) {
		// TODO enable/disable text field
		// (this.localPlayerSlot.identity.username as TextField).disabled = !value
	}

	setReadyButtonActivation(value: boolean) {
		// TODO enable/disable ready checkbox
	}

	updateStartCountdown(value: number) {
		this.showLoader(`Game starts in ${value}s...`);
	}

	update(framesPassed: number): void {}
}
