import { Client, Room } from "colyseus.js";
import { SceneManager } from "./SceneManager";
import { IScene } from "@/scenes/IScene";
import { RoomType } from "server/rooms/RoomType";
import { ClientRoom } from "@/rooms/ClientRoom";
import { LobbyRoom } from "@/rooms/LobbyRoom";
import { WaitingRoom } from "@/rooms/WaitingRoom";
import { GameRoom } from "@/rooms/GameRoom";
import { GameOverRoom } from "@/rooms/GameOverRoom";
import { GameOverData } from "server/messages/Messages";

enum LocalStorageKey {
	USERNAME = "username",
}

export class GameManager {
	private constructor() {}

	// Private properties
	private static _client: Client;
	private static _sceneManager: SceneManager;
	private static _currentRoom?: ClientRoom<IScene>;

	static get sceneManager() {
		return GameManager._sceneManager;
	}

	static get clientID() {
		return GameManager._currentRoom?.room.sessionId;
	}

	static initialize(client: Client, sceneManager: SceneManager) {
		GameManager._client = client;
		GameManager._sceneManager = sceneManager;
	}

	static joinLobby() {
		GameManager._client
			.joinOrCreate(RoomType.LobbyRoom)
			.then((room: Room) => {
				console.log(room.sessionId, "joined", room.name);
				GameManager.changeRoom(new LobbyRoom(room));
			})
			.catch((e) => console.log(e));
	}

	static joinGame(roomId?: string, options?: object) {
		const success = (room: Room) => {
			console.log("Joined room", room.roomId);
			GameManager.changeRoom(new WaitingRoom(room));
		};
		const error = (e: any) => console.log(e);

		// Leave current room before joining a new one
		GameManager.leaveRoom();

		// Set username to the last used one
		const mergedOptions = { ...options, username: localStorage.getItem(LocalStorageKey.USERNAME) ?? undefined };

		if (roomId) {
			console.log("Joining game with ID", roomId);
			GameManager._client.joinById(roomId, mergedOptions).then(success).catch(error);
		} else {
			console.log("Joining random game room");
			GameManager._client.create(RoomType.GameRoom, mergedOptions).then(success).catch(error);
		}
		// TODO - Change Hash when joining room (ex: #room-<roomID>), see: https://github.com/endel/colyseus-collaborative-drawing/blob/master/src/client/index.ts
	}

	static startGame(room: Room) {
		GameManager.changeRoom(new GameRoom(room));
	}

	static endGame(room: Room, data: GameOverData) {
		GameManager.changeRoom(new GameOverRoom(room, data));
	}

	static setReady(value: boolean) {
		if (GameManager._currentRoom instanceof WaitingRoom) GameManager._currentRoom.setReady(value);
		else console.log("Cannot set 'ready' state when not in Game room");
	}

	static changePlayerName(value: string) {
		if (GameManager._currentRoom instanceof WaitingRoom) {
			// Cache username to re-use it the next time 
			localStorage.setItem(LocalStorageKey.USERNAME, value.trim());
			GameManager._currentRoom.changePlayerName(value);
		} else console.log("Cannot change player name when not in Game room");
	}

	static returnToLobby() {
		if (!(GameManager._currentRoom instanceof LobbyRoom)) {
			GameManager.leaveRoom();
			GameManager.joinLobby();
		} else console.log("Already in lobby!");
	}

	static leaveRoom() {
		GameManager._currentRoom ? GameManager._currentRoom.leave() : console.log("No room to leave!");
	}

	protected static changeRoom(room: ClientRoom<IScene>) {
		// Change room
		GameManager._currentRoom = room;
		// Change scene
		GameManager._sceneManager.changeScene(room.scene);
	}
}
