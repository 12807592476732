import { Sprite } from "pixi.js";
import type { RoleState } from "server/state/RoleState";
import { ImprovedContainer, StatefulContainer } from "./Containers";

export class Role extends ImprovedContainer implements StatefulContainer<RoleState> {
	avatar!: Sprite;

	constructor(state: RoleState) {
		super();
		this.updateState(state);
	}

	updateState(state: RoleState) {
		if (this.avatar) this.removeChild(this.avatar);
		this.avatar = Sprite.from(state?.type ?? "Mystery-man");
		this.addChild(this.avatar);
		this.setPivotToCenter();
	}
}
