import { Layout, type LayoutOptions } from "@pixi/ui";
import { Container } from "pixi.js";

export class RowLayout extends Layout {
	maxWidth: number;

	constructor(options: { maxWidth: number } & LayoutOptions) {
		super({ type: "horizontal", ...options });
		this.maxWidth = options.maxWidth;

		this.on("childAdded", (child) => this.updateLayout());
		this.on("childRemoved", (child) => this.updateLayout());
	}

	updateLayout() {
		if (this.children.length > 0) {
			// Width occupied by the components
			const filledWidth = this.children.map((comp) => comp.width).reduce((sum, value) => sum + value);
			// Remaining width available for spacing
			const availableWidth = this.maxWidth - filledWidth;
			// Individual width of space between each component
			const spacing = this.children.length > 1 ? availableWidth / (this.children.length - 1) : availableWidth;

			let previous: Container;
			this.children.forEach((comp) => {
				if (previous) comp.x = previous.x + previous.width + spacing;
				else comp.x = 0;
				previous = comp;
			});
		}
	}
}
