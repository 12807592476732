import { Room } from "colyseus.js";
import { GameScene } from "@/scenes/GameScene";
import { ClientRoom } from "./ClientRoom";
import {
	ClientMessages,
	ErrorCodes,
	ServerMessages,
	RoundPauseReason,
	type GameOverData,
} from "server/messages/Messages";
import { GameManager } from "@/managers/GameManager";
import type { GameState } from "server/state/GameState";

export class GameRoom extends ClientRoom<GameScene> {
	constructor(room: Room) {
		super(room, new GameScene());

		this.scene.onCardClick = (cardId: number) => {
			// Does not check if the local player can cut wires (checked by server)
			this.room.send(ClientMessages.CutCable, { cardId: cardId });
		};

		this.scene.onCardHoverIn = (cardId: number) => {
			// Highlight card (if current player has the wire cutter only)
			if (this.hasWireCutter()) this.room.send(ClientMessages.HighlightCard, { cardId: cardId, value: true });
		};

		this.scene.onCardHoverOut = (cardId: number) => {
			// Stop highlighting card (if current player has the wire cutter only)
			if (this.hasWireCutter()) this.room.send(ClientMessages.HighlightCard, { cardId: cardId, value: false });
		};

		// Draw players based on current state
		this.scene.setPlayers(this.room.state);
	}

	protected hasWireCutter() {
		return (this.room.state as GameState).players.get(this.room.sessionId)?.hasWireCutter;
	}

	protected setupMessageCallbacks(): void {
		this.room.onMessage(ServerMessages.RoundPause, (message) => {
			switch (message as RoundPauseReason) {
				// Round wrap sequence
				case RoundPauseReason.RoundWrapStart:
					return this.scene.showLoader("Wrapping round...");
				case RoundPauseReason.RoundWrapEnd:
					return this.scene.hideLoader();
				// Card shuffling sequence
				case RoundPauseReason.ShuffleStart:
					return this.scene.showLoader("Shuffling cards...");
				case RoundPauseReason.ShuffleEnd:
					return this.scene.hideLoader();
				// Player hand display sequence
				case RoundPauseReason.ShowHand:
					this.scene.showLoader("Examine your new cards...");
					return this.scene.showPlayerHand();
				case RoundPauseReason.HideHand:
					this.scene.hideLoader();
					return this.scene.hidePlayerHand();
			}
		});

		this.room.onMessage(ServerMessages.GameOver, (message: GameOverData) => {
			GameManager.endGame(this.room, message);
		});
	}

	protected setupStateCallbacks(): void {
		// Update scene on each state update
		this.room.onStateChange((state) => this.scene.updateState(state));
	}

	protected onLeave(code: number): void {
		console.log("Left game room");
	}

	protected onError(code: number, message?: string): void {
		if (code === ErrorCodes.GameAbortedAfterPlayerLeft) {
			super.onError(code, "A player left the room. Game aborted.");
		} else {
			super.onError(code, message);
		}
	}
}
