/**
 * These classes are adapted from the snippets provided by @miltoncandelero
 * @see https://www.pixijselementals.com/#the-manager-class
 */

import { Application, Sprite, Texture, TilingSprite } from "pixi.js";
import { IScene } from "@/scenes/IScene";

export class SceneManager {
	private _app: Application;
	private _currentScene!: IScene;
	private _background: Sprite;

	private _width: number;
	private _height: number;

	public get width(): number {
		return this._width;
	}
	public get height(): number {
		return this._height;
	}

	constructor() {
		this._width = 1920;
		this._height = 1080;

		// Create Application
		this._app = new Application({
			view: document.getElementById("canvas") as HTMLCanvasElement,
			resolution: window.devicePixelRatio || 1,
			autoDensity: true,
			backgroundColor: 0xffffff, // white background color to blend well with background texture
			width: this.width,
			height: this.height,
		});

		// Create semi-transparent background texture
		const texture = Texture.from("background-texture");
		this._background = new TilingSprite(texture, this.width, this.height);
		this._background.alpha = 0.75;

		this._app.stage.addChild(this._background);
		this._app.ticker.add(this.update);

		// listen for the browser telling us that the screen size changed
		window.addEventListener("resize", () => this.resize());

		// call it manually once so we are sure we are the correct size after starting
		this.resize();
	}

	public resize(): void {
		// current screen size
		const screenWidth = document.documentElement.clientWidth || window.innerWidth || 0;
		const screenHeight = document.documentElement.clientHeight || window.innerHeight || 0;		

		// uniform scale for our game
		const scale = Math.min(screenWidth / this.width, screenHeight / this.height);

		// the "uniformly englarged" size for our game
		const enlargedWidth = Math.floor(scale * this.width);
		const enlargedHeight = Math.floor(scale * this.height);

		// margins for centering our game
		const horizontalMargin = (screenWidth - enlargedWidth) / 2;
		const verticalMargin = (screenHeight - enlargedHeight) / 2;

		// now we use css trickery to set the sizes and margins
		this._app.view.style!.width = `${enlargedWidth}px`;
		this._app.view.style!.height = `${enlargedHeight}px`;
		(this._app.view.style as any).marginLeft = (this._app.view.style as any).marginRight = `${horizontalMargin}px`;
		(this._app.view.style as any).marginTop = (this._app.view.style as any).marginBottom = `${verticalMargin}px`;
	}

	// Call this function when you want to go to a new scene
	public changeScene(newScene: IScene): void {
		// Remove and destroy old scene... if we had one..
		if (this._currentScene) {
			this._app.stage.removeChild(this._currentScene);
			this._currentScene.destroy();
		}

		// Add the new one
		this._currentScene = newScene;
		this._app.stage.addChild(this._currentScene);
	}

	// This update will be called by a pixi ticker and tell the scene that a tick happened
	private update(framesPassed: number): void {
		// Let the current scene know that we updated it...
		this._currentScene?.update(framesPassed);
	}
}
