import { Input } from "@pixi/ui";
import { Graphics } from "pixi.js";
import { ImprovedContainer, StatefulContainer } from "../Containers";

export class TextField extends ImprovedContainer implements StatefulContainer<string> {
	view: Input;

	protected readonly _borderColor = 0x777777;
	protected readonly _bgColor = 0xffffff;
	protected readonly _textColor = 0;
	protected readonly _width = 248;
	protected readonly _height = 48;
	protected readonly _radius = 8;
	protected readonly _border = 2;

	constructor(state: string, onChange: (value: string) => void) {
		super();
		this.view = new Input({
			bg: new Graphics()
				.beginFill(this._borderColor)
				.drawRoundedRect(0, 0, this._width, this._height, this._radius + this._border)
				.beginFill(this._bgColor)
				.drawRoundedRect(
					this._border,
					this._border,
					this._width - this._border * 2,
					this._height - this._border * 2,
					this._radius
				),
			padding: this._border ? this._border + 3 : 0,
			textStyle: {
				fill: this._textColor,
			},
			align: "center",
			value: state,
		});
		this.view.onChange.connect(() => onChange(this.view.value));
		this.addChild(this.view);
	}

	updateState(state: string): void {
		this.view.value = state;
	}
}
