import type { Room, RoomAvailable } from "colyseus.js";
import { LobbyScene } from "@/scenes/LobbyScene";
import { ClientRoom } from "./ClientRoom";
import { ServerMessages } from "server/messages/Messages";

export class LobbyRoom extends ClientRoom<LobbyScene> {
	constructor(room: Room) {
		super(room, new LobbyScene());
	}

	protected setupMessageCallbacks() {
		// Sends the full lobby state
		this.room.onMessage("rooms", (rooms: RoomAvailable[]) => this.scene.setAvailableRooms(rooms));
		// Sent upon room creation or update
		this.room.onMessage("+", ([roomId, room]) => this.scene.addAvailableRoom(room));
		// Sent upon room destruction
		this.room.onMessage("-", (roomId) => this.scene.removeAvailableRoom(roomId));
		// Sent upon total player count update
		this.room.onMessage(ServerMessages.TotalPlayerCountUpdate, (count) => this.scene.updatePlayerCount(count));
	}

	protected setupStateCallbacks(): void {}

	protected onLeave(code: number): void {
		console.log("Left lobby");
	}
}
