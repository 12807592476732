import { Client, Room } from "colyseus.js";
import { GameManager } from "./managers/GameManager";
import { SceneManager } from "./managers/SceneManager";
import { loadAssets } from "./scenes/containers/Sprites";

const host = window.document.location.host.replace(/:.*/, "");
const port = process.env.NODE_ENV === "development" ? 2567 : location.port;
const serverURL = location.protocol.replace("http", "ws") + "//" + host + (port ? `:${port}` : "");

window.addEventListener("DOMContentLoaded", () => {
	// Connect to game server
	const client = new Client(serverURL);

	// Display game once assets are loaded
	loadAssets().then(() => {
		// Initialise scene manager
		const sceneManager = new SceneManager();

		// Initialise game manager
		GameManager.initialize(client, sceneManager);
		GameManager.joinLobby();

		// // DEBUG - In waiting room 
		// GameManager.joinGame(undefined, {debug: true});

		// // DEBUG - End of round panel
		// GameManager.endGame(new Room("debug"), { reason: 2});
	});
});
