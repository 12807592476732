import { Container, Sprite } from "pixi.js";

export class Logo extends Container {
	logo: Sprite;

	constructor(scale: number = 1) {
		super();
		this.logo = Sprite.from("logo");
		this.logo.alpha = 0.5;
		this.logo.scale.set(scale);
		this.addChild(this.logo);
	}
}
