import { GameManager } from "@/managers/GameManager";
import type { RoomAvailable } from "colyseus.js";
import { Container, Text } from "pixi.js";
import { zip } from "server/utils/common";
import { Logo } from "./containers/Logo";
import { Button } from "./containers/ui/Button";
import { ItemList } from "./containers/ui/ItemList";
import { RoomAvailableView } from "./containers/ui/RoomAvailableView";
import { IScene } from "./IScene";

export class LobbyScene extends Container implements IScene {
	logo: Logo;
	playerCount: Text;

	// roomId -> RoomAvailableView map. Allows for add/remove/update operations on roomList
	roomViews: Map<string, RoomAvailableView>;
	roomList: ItemList<RoomAvailableView>;

	createRoomBtn: Button;

	protected readonly _innerMargin = 48;

	constructor() {
		super();
		this.logo = new Logo();
		this.logo.position.set(
			GameManager.sceneManager.width / 2 - this.logo.width / 2,
			GameManager.sceneManager.height * 0.05
		);
		this.playerCount = new Text();
		this.updatePlayerCount(0);
		this.roomList = new ItemList(
			{
				width: GameManager.sceneManager.width / 2,
				height: GameManager.sceneManager.height / 2,
			},
			new Text("No room open. You can create one below.")
		);

		this.roomViews = new Map<string, RoomAvailableView>();

		this.createRoomBtn = new Button({
			width: 164,
			height: 72,
			fontSize: 24,
			text: "Create room",
			onTap: () => GameManager.joinGame(),
		});

		this.addChild(this.logo, this.playerCount, this.roomList, this.createRoomBtn);
	}

	/**
	 * Replace the current display of rooms.
	 * @param rooms the list of available rooms
	 */
	setAvailableRooms(rooms: RoomAvailable[]) {
		// Clear stored room items and items in list
		this.roomViews.clear();
		this.roomList.clearItems();

		// Create room items
		const views = rooms.map((room) => new RoomAvailableView(room, () => GameManager.joinGame(room.roomId)));
		// Store each view item in map for future matching using the RoomID
		zip(rooms, views).forEach(([room, view]) => {
			if (room && view) this.roomViews.set(room.roomId, view);
		});
		// Add views to scrollable list
		this.roomList.addItem(...views);

		this.updateLayout();
	}

	addAvailableRoom(room: RoomAvailable) {
		// Create room item
		const view = new RoomAvailableView(room, () => GameManager.joinGame(room.roomId));
		// Check if the room is already listed.
		// If true, only update the individual view with the new state
		// Otherwise, add the new item to map and list
		const previousView = this.roomViews.get(room.roomId);
		if (previousView) {
			previousView.updateState(room);
		} else {
			this.roomViews.set(room.roomId, view);
			this.roomList.addItem(view);
		}

		this.updateLayout();
	}

	removeAvailableRoom(roomId: string) {
		// Remove item for map and scrollable list
		const view = this.roomViews.get(roomId);
		if (view) {
			this.roomViews.delete(roomId);
			this.roomList.removeItem(view);
		}
		this.updateLayout();
	}

	updatePlayerCount(count: number) {
		this.playerCount.text = `${count} available players`;
		this.playerCount.position.set(
			this.logo.x + this.logo.width / 2 - this.playerCount.width / 2,
			this.logo.y + this.logo.height + this._innerMargin
		);
	}

	protected updateLayout() {
		this.roomList.position.set(
			GameManager.sceneManager.width / 2 - this.roomList.width / 2,
			this.playerCount.y + this.playerCount.height + this._innerMargin * 3
		);

		this.createRoomBtn.position.set(
			this.roomList.x + this.roomList.width / 2 - this.createRoomBtn.width / 2,
			this.roomList.y + this.roomList.height + this._innerMargin * 3
		);
	}

	update(framesPassed: number): void {}
}
