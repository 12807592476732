import { Container, Graphics, Sprite, Text, Ticker } from "pixi.js";

export class Loader extends Container {
	protected _clock: Sprite;
	protected _clockMinuteHand: Graphics;
	protected _clockSecondHand: Graphics;
	protected _text: Text;
	protected _message: string | number | undefined;
	protected _started: boolean = false;

	constructor() {
		super();

		this._clock = Sprite.from("tachometer");
		this._clockMinuteHand = this.getClockHand(this._clock.height * 0.14);
		this._clockSecondHand = this.getClockHand(this._clock.height * 0.18);
		this._text = new Text();
		this.addChild(this._clock, this._text, this._clockMinuteHand, this._clockSecondHand);

		Ticker.shared.add(this.update, this);
        
		this.stop();
	}

	get message() {
		return this._message;
	}

	set message(value: typeof this._message) {
		this._message = value;
		this._text.text = this.message ?? "";
	}

	start() {
		this.reset();
		this.visible = true;
		this._started = true;
	}

	stop() {
		this.visible = false;
		this._started = false;
	}

	reset() {
		this._clockMinuteHand.position.set(this._clock.width / 2, 5 + this._clock.height / 2);
		this._clockSecondHand.position.set(this._clock.width / 2, 5 + this._clock.height / 2);
		this._clockMinuteHand.angle = 0;
		this._clockSecondHand.angle = 0;
		this._text.position.set(this._clock.width + 16, this._clock.height / 2 - this._text.height / 2);
	}

	update(framesPassed: number) {
		if (this._started) {
			this._clockMinuteHand.angle += framesPassed * 0.5;
			this._clockSecondHand.angle += framesPassed * 5;
		}
	}

	protected getClockHand(length: number) {
		return new Graphics().lineStyle(3, 0xa7613d).moveTo(0, 0).lineTo(0, length);
	}
}
