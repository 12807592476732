/**
 * This class is adapted from the snippets provided by @anvoevodin
 * @see https://medium.com/anvoevodin/flexible-ui-buttons-with-pixijs-v5-using-ninesliceplane-39234efa1507
 */
import { NineSlicePlane, Text, Texture } from "pixi.js";
interface Settings {
	width: number;
	height: number;

	text: string;
	fontSize: number;

	tint: number;
	overTint: number;
	activeTint: number;

	onTap: () => void;
}

// Default values
const defaultSettings: Settings = {
	width: 200,
	height: 100,

	fontSize: 35,
	text: "",

	tint: 0xffffff,
	overTint: 0xdddddd,
	activeTint: 0xaaaaaa,

	onTap: () => {},
} as const;

export class Button extends NineSlicePlane {
	settings: Settings;
	label: Text;
	isPressed: boolean = false;
	isHovered: boolean = false;

	constructor(settings: Partial<Settings>) {
		const texture = Texture.from("button-purple");
		const notScalableArea = 20; // Indent from left, top, right and bottom sides in pixels
		super(texture, notScalableArea, notScalableArea, notScalableArea, notScalableArea);

		/** Contains settings for the button */
		this.settings = defaultSettings;

		// Main text on the button
		this.label = new Text("");
		this.label.anchor.set(0.5);
		this.addChild(this.label);

		// Update visual appearance
		this.update(settings);

		// Setup interaction logic
		this.interactive = true;
		this.cursor = "pointer";
		this.on("pointertap", () => this.onTap());
		this.on("pointerover", () => this.onOver());
		this.on("pointerout", () => this.onOut());
		this.on("pointerdown", () => this.onDown());
		this.on("pointerup", () => this.onUp());
		this.on("pointerupoutside", () => this.onUp());
	}

	onTap() {
		this.settings.onTap?.();
	}

	onOver() {
		this.isHovered = true;
		this.update();
	}

	onOut() {
		this.isHovered = false;
		this.update();
	}

	onDown() {
		this.isPressed = true;
		this.update();
	}

	onUp() {
		this.isPressed = false;
		this.update();
	}

	/** Updates the button's appearance after changing its settings */
	update(settings?: Partial<Settings>) {
		// Creating new settings which include old ones and apply new ones over it
		this.settings = {
			...this.settings, // including old settings
			...settings, // including new settings
		};

		if (this.isPressed === true) this.tint = this.settings.activeTint;
		else if (this.isHovered === true) this.tint = this.settings.overTint;
		else this.tint = this.settings.tint;

		this.label.text = this.settings.text;
		this.label.style = {
			fontSize: this.settings.fontSize + "px",
			fill: "#ffffff",
		};

		this.onResize();
	}

	/** Changes sizes and positions each time when the button updates */
	onResize() {
		this.width = this.settings.width;
		this.height = this.settings.height;

		this.label.x = this.width * 0.5;
		this.label.y = this.height * 0.5 + -4; // Apply Y-offset on text to compensate the shadow in the sprite
	}
}
