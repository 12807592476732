import { Container, Graphics } from "pixi.js";

export class DebugDraw {
	appliedTo: Container;

	protected _origin!: Graphics;
	protected _pivot!: Graphics;
	protected _boundingBox!: Graphics;

	constructor(applyTo: Container) {
		this.appliedTo = applyTo;
	}

	drawOrigin(x: number, y: number, color: number = 0xff0000) {
		if (!this._origin) {
			this._origin = new Graphics();
			this.appliedTo.addChild(this._origin);
		}
		this._origin.clear();
		this._origin.beginFill(color);
		this._origin.drawCircle(0, 0, 5);
		this._origin.endFill();
		this._origin.position.set(x, y);
	}

	drawPivot(color: number = 0xff0000) {
		if (!this._pivot) {
			this._pivot = new Graphics();
			this.appliedTo.addChild(this._pivot);
		}
		this._pivot.clear();
		this._pivot.beginFill(color);
		this._pivot.drawCircle(0, 0, 8);
		this._pivot.endFill();
		this._pivot.position.set(this.appliedTo.pivot.x, this.appliedTo.pivot.y);
	}

	drawBoundingBox(color: number = 0x00ff00) {
		if (!this._boundingBox) {
			this._boundingBox = new Graphics();
			this.appliedTo.addChild(this._boundingBox);
		}
		const rect = this.appliedTo.getLocalBounds();
		this._boundingBox.clear();
		this._boundingBox.lineStyle(1, color);
		this._boundingBox.drawRect(rect.x, rect.y, rect.width, rect.height);
		this._boundingBox.position.set(0, 0);
	}
}

export class ImprovedContainer extends Container {
	readonly debug = new DebugDraw(this);

	rotateAroundCenter(deg: number) {
		this.position.set(this.width / 2, this.height / 2);
		this.pivot.set(this.width / 2, this.height / 2);
		this.angle = deg;
	}

	setPivotToCenter() {
		const rect = this.getLocalBounds();
		this.pivot.set(rect.x + rect.width / 2, rect.y + rect.height / 2);
	}

	moveForward(distance: number, referenceAxis: "x" | "y" = "x") {
		const rad = referenceAxis === "y" ? this.rotation - Math.PI / 2 : this.rotation;
		this.x += distance * Math.cos(rad);
		this.y += distance * Math.sin(rad);
	}

	protected placeRelativeTo(child: Container, x: number, y: number, offsetX: number, offsetY: number) {
		child.position.x = x + offsetX;
		child.position.y = y + offsetY;
	}

	protected placeRelativeToPivot(child: Container, offsetX: number, offsetY: number) {
		this.placeRelativeTo(child, this.pivot.x, this.pivot.y, offsetX, offsetY);
	}
}

export interface StatefulContainer<State> extends Container {
	updateState(state: State): void;
	updateLayout?: () => void;
}
