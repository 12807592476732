const adjectives = [
	"Naughty",
	"Bright",
	"Artistic",
	"Heavy",
	"Weird",
	"Gloomy",
	"Clean",
	"Gorgeous",
	"Slow",
	"Chill",
	"Beautiful",
	"Honor",
	"Doom",
	"Light",
	"Jolly",
	"Perfect",
	"Jumbo",
	"Adorable",
	"Color",
	"Laughing",
	"Angry",
	"Incredible",
	"Candid",
	"Liquid",
	"Agile",
	"Money",
	"Bouncing",
	"Haunted",
	"Brown",
	"Active",
	"Frosty",
	"Scented",
	"Prime",
	"Cold",
];

const nouns = [
	"Flame",
	"Villain",
	"Urchin",
	"Hyena",
	"Mule",
	"Khajiit",
	"Centaur",
	"Rover",
	"Grapefruit",
	"Warhog",
	"Enigma",
	"Emu",
	"Bee",
	"Juniper",
	"Monk",
	"Soldier",
	"Dessert",
	"Raider",
	"Mantis",
	"Goldfish",
	"Prowler",
	"Dwarf",
	"Nut",
	"Piggy",
	"Fury",
	"Puppy",
	"Stalker",
	"Gull",
	"Crusader",
	"Kitten",
	"Eland",
	"Peafowl",
	"Bronco",
	"Ninja",
	"Robot",
	"Vanilla",
	"Phantasm",
	"Ram",
];

export default {
	generatePlayerName() {
		const adjIdx = Math.floor(Math.random() * adjectives.length);
		const nounIdx = Math.floor(Math.random() * nouns.length);
		return adjectives[adjIdx] + nouns[nounIdx];
	},
	generateRandomPositiveInteger(max: number = Number.MAX_SAFE_INTEGER) {
		return Math.floor(Math.random() * max);
	},
};
