import { Schema, type } from "@colyseus/schema";
import generator from "../utils/generator";
import { PlayerId } from "./PlayerState";

export enum CardType {
	Safe = "Card-safe",
	Fuse = "Card-fuse",
	Bomb = "Card-bomb",
}

export class CardState extends Schema {
	// HACK: Hardcode enum underlying type to enable serialization (because Colyseus does not accept custom enum types)
	@type("string")
	type: CardType;

	@type("number")
	id: number = generator.generateRandomPositiveInteger();

	// HACK: Hardcode underlying type to enable serialization (because Colyseus does not accept custom types)
	@type("string")
	belongsTo?: PlayerId;

	@type("boolean")
	highlighted: boolean = false;

	@type("string")
	revealed: "no" | "locally" | "publicly" = "no";

	@type("boolean")
	cut: boolean = false;

	constructor(type: CardType) {
		super();
		this.type = type;
	}
}
