import { RoomAvailable } from "colyseus.js";
import { Sprite, Text, TextStyle } from "pixi.js";
import { ImprovedContainer, StatefulContainer } from "../Containers";
import { Button } from "./Button";

export class RoomAvailableView extends ImprovedContainer implements StatefulContainer<RoomAvailable> {
	icon: Sprite;
	desc: Text;
	joinBtn: Button;

	protected readonly _innerMargin = 64;

	constructor(state: RoomAvailable, onJoin: () => void) {
		super();

		this.icon = Sprite.from("tachometer");
		this.icon.scale.set(0.75, 0.75);

		this.desc = new Text(this.getText(state), new TextStyle({ fontSize: 32 }));
		this.desc.position.set(this.icon.width + this._innerMargin, this.icon.height / 2 - this.desc.height / 2);

		this.joinBtn = new Button({ width: 96, height: 56, text: "Join", fontSize: 28, onTap: onJoin });
		this.joinBtn.position.set(
			this.desc.x + this.desc.width + this._innerMargin,
			this.desc.y + this.desc.height / 2 - this.joinBtn.height / 2
		);

		this.addChild(this.icon, this.desc, this.joinBtn);
	}

	updateState(state: RoomAvailable): void {
		this.desc.text = this.getText(state);
	}

	protected getText(state: RoomAvailable) {
		return `Room #${state.roomId} - ${state.clients}/${state.maxClients}`;
	}
}
