import { Assets, ResolverManifest } from "pixi.js";
import { CardType } from "server/state/CardState";
import { RoleType } from "server/state/RoleState";

function fromAssetsDir(file: string) {
	return `./assets/${file}`; // Relative to index.html
}

const manifest: ResolverManifest = {
	bundles: [
		{
			name: "cards",
			assets: {
				[CardType.Safe]: fromAssetsDir("cards/safe-card.png"),
				[CardType.Fuse]: fromAssetsDir("cards/fuse-card.png"),
				[CardType.Bomb]: fromAssetsDir("cards/bomb-card.png"),
				"Back-face": fromAssetsDir("cards/back-face.jpg"),
			},
		},
		{
			name: "roles",
			assets: {
				[RoleType.Moriarty]: fromAssetsDir("roles/moriarty.png"),
				[RoleType.Adler]: fromAssetsDir("roles/adler.png"),
				[RoleType.Sbire]: fromAssetsDir("roles/sbire.png"),
				[RoleType.Sherlock]: fromAssetsDir("roles/sherlock.png"),
				[RoleType.Watson]: fromAssetsDir("roles/watson.png"),
				[RoleType.Lestrade]: fromAssetsDir("roles/lestrade.png"),
				[RoleType.Hudson]: fromAssetsDir("roles/hudson.png"),
				[RoleType.MissWatson]: fromAssetsDir("roles/miss-watson.png"),
				"Mystery-man": fromAssetsDir("roles/mystery-man.png"),
			},
		},
		{
			name: "tools",
			assets: {
				"wire-cutter": fromAssetsDir("tools/wire-cutter.png"),
				tachometer: fromAssetsDir("tools/tachometer.png"),
			},
		},
		{
			name: "teams",
			assets: {
				"team-moriarty": fromAssetsDir("teams/team-moriarty.png"),
				"team-sherlock": fromAssetsDir("teams/team-sherlock.png"),
			},
		},
		{
			name: "ui",
			assets: {
				logo: fromAssetsDir("ui/logo.png"),
				"background-texture": fromAssetsDir("ui/background-texture.png"),
				"button-purple": fromAssetsDir("ui/buttons/purple.png"),
				"button-brown": fromAssetsDir("ui/buttons/brown.png"),
				"checkbox-false": fromAssetsDir("ui/checkbox-false.png"),
				"checkbox-true": fromAssetsDir("ui/checkbox-true.png"),
				eye: fromAssetsDir("ui/eye.png"),
			},
		},
	],
};

export async function loadAssets() {
	await Assets.init({ manifest });
	const bundleIds = manifest.bundles.map((bundle) => bundle.name);
	await Assets.loadBundle(bundleIds);
}
