import { GrayscaleFilter } from "@pixi/filter-grayscale";
import { Text, TextStyle } from "pixi.js";
import { PlayerState } from "server/state/PlayerState";
import { truncateString } from "server/utils/common";
import { ImprovedContainer, StatefulContainer } from "./Containers";
import { Role } from "./Role";
import { TextField } from "./ui/TextField";

export class Identity extends ImprovedContainer implements StatefulContainer<PlayerState> {
	username: Text;
	role: Role;

	protected _greyscale: boolean;
	protected readonly _maxUsernameLength = 15;
	protected readonly _innerMargin = 16;

	constructor(state: PlayerState) {
		super();
		this.username = new Text(
			truncateString(state.name, this._maxUsernameLength),
			new TextStyle({
				fontVariant: "small-caps",
				fontWeight: "bold",
			})
		);
		this.role = new Role(state.role);

		this.addChild(this.role, this.username);
		this._greyscale = !state?.isReady;
		this.updateLayout();
	}

	updateState(state: PlayerState): void {
		if (this.username instanceof Text) this.username.text = truncateString(state?.name, this._maxUsernameLength);
		this.role.updateState(state?.role);
		this._greyscale = !state?.isReady;
		this.updateLayout();
	}

	updateLayout() {
		this.username.position.set(0, this.role.height + this._innerMargin);
		this.role.position.set(this.username.width / 2, this.role.height / 2);
		this.role.filters = this._greyscale ? [new GrayscaleFilter()] : [];
	}
}

export class EditableIdentity extends Identity {
	textField: TextField;

	constructor(state: PlayerState, onChange: (value: string) => void) {
		super(state);
		this.textField = new TextField(state.name, (value) => onChange(value));

		// Replace username label by a text field
		this.removeChild(this.username);
		this.addChild(this.textField);
		this.updateLayout();
	}

	setUsername(username: string): void {
		this.textField.view.value = username;
	}

	updateLayout() {
		super.updateLayout();
		// Safeguard - Because super-constructor calls this method BEFORE this class' constructor is initialized
		if (this.textField) {
			this.textField.position.set(0, this.role.height + this._innerMargin);
			this.role.position.set(this.textField.width / 2, this.role.height / 2);
		}
	}
}
