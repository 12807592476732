import { Room } from "colyseus.js";
import { ClientRoom } from "./ClientRoom";
import { GameOverReason, type GameOverData } from "server/messages/Messages";
import { GameOverScene, TeamMoriartyWins, TeamSherlockWins } from "@/scenes/GameOverScene";
import { GameManager } from "@/managers/GameManager";

export class GameOverRoom extends ClientRoom<GameOverScene> {
	constructor(room: Room, data: GameOverData) {
		const onLeave = () => GameManager.returnToLobby();
		let scene;
		switch (data.reason as GameOverReason) {
			case GameOverReason.BombTriggered:
				scene = new TeamMoriartyWins("Bomb triggered!", onLeave);
				break;
			case GameOverReason.BombNotDefused:
				scene = new TeamMoriartyWins("Bomb not defused!", onLeave);
				break;
			case GameOverReason.BombDefused:
				scene = new TeamSherlockWins("Bomb defused!", onLeave);
				break;
		}
		super(room, scene);
	}

	protected setupMessageCallbacks(): void {}
	protected setupStateCallbacks(): void {}
	protected setupControlCallbacks(): void {}
}
