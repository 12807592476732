import type { PlayerState } from "server/state/PlayerState";
import { CardHand } from "./CardHand";
import { Identity } from "./Identity";
import { ImprovedContainer, StatefulContainer } from "./Containers";
import { WireCutter } from "./WireCutter";

export type Location = "bottom" | "left" | "top" | "right";

export class Player extends ImprovedContainer implements StatefulContainer<PlayerState> {
	identity: Identity;
	hand: CardHand;
	wireCutter: WireCutter;

	readonly isMe: boolean;

	protected _location: Location = "bottom";
	protected readonly _innerMargin = 8;

	constructor(state: PlayerState, isMe: boolean) {
		super();
		this.isMe = isMe;
		this.identity = new Identity(state);
		this.hand = new CardHand(state?.hand, isMe);
		this.wireCutter = new WireCutter(state.hasWireCutter);

		this.addChild(this.wireCutter, this.hand, this.identity);
		this.location = "bottom";
	}

	get location() {
		return this._location;
	}

	set location(value: typeof this._location) {
		this._location = value;
		this.updateLayout();
	}

	updateState(state: PlayerState) {
		this.identity.updateState(state);
		this.hand.updateState(state?.hand);
		this.wireCutter.updateState(state?.hasWireCutter);
		this.updateLayout();
	}

	updateLayout() {
		switch (this.location) {
			case "bottom":
				this.bottomArrangement();
				break;
			case "top":
				this.topArrangement();
				break;
			case "left":
				this.leftArrangement();
				break;
			case "right":
				this.rightArrangement();
				break;
		}
		this.updatePivot();
	}

	protected bottomArrangement() {
		this.hand.position.set(0, 0);
		this.hand.rotateAroundCenter(0);
		this.identity.position.set(
			this.hand.width + this._innerMargin,
			this.hand.height / 2 - this.identity.height / 2
		);
		this.wireCutter.rotateAroundCenter(0);
		this.wireCutter.position.set(
			this.hand.x - this.hand.width / 2 - this.wireCutter.width / 2 - this._innerMargin,
			this.hand.y - (this.wireCutter.height - this.hand.height) / 2
		);
	}

	protected topArrangement() {
		this.hand.position.set(0, 0);
		this.hand.rotateAroundCenter(180);
		const identityY = Math.max(0, this.hand.y - this.identity.height / 2); // Avoids identity to have Y < 0
		this.identity.position.set(-this.identity.width - this._innerMargin, identityY);
		this.wireCutter.rotateAroundCenter(180);
		this.wireCutter.position.set(
			this.hand.x + this.hand.width / 2 + this.wireCutter.width / 2 + this._innerMargin,
			this.hand.y + (this.wireCutter.height - this.hand.height) / 2
		);
	}

	protected leftArrangement() {
		this.hand.position.set(0, 0);
		this.hand.rotateAroundCenter(90);
		this.hand.x -= this.hand.height;
		this.identity.position.set(
			this.hand.x - this.identity.width / 2,
			this.hand.y + this.hand.width / 2 + this._innerMargin
		);
		this.wireCutter.rotateAroundCenter(90);
		this.wireCutter.position.set(
			this.hand.x + (this.wireCutter.height - this.hand.height) / 2,
			this.hand.y - this.hand.width / 2 - this.wireCutter.width / 2 - this._innerMargin
		);
	}

	protected rightArrangement() {
		this.hand.position.set(0, 0);
		this.hand.rotateAroundCenter(-90);
		this.identity.position.set(
			this.hand.x - this.identity.width / 2,
			this.hand.y + this.hand.width / 2 + this._innerMargin
		);
		this.wireCutter.rotateAroundCenter(-90);
		this.wireCutter.position.set(
			this.hand.x - (this.wireCutter.height - this.hand.height) / 2,
			this.hand.y - this.hand.width / 2 - this.wireCutter.width / 2 - this._innerMargin
		);
	}

	protected updatePivot() {
		const rect = this.getLocalBounds();
		switch (this.location) {
			case "bottom":
				this.pivot.set(rect.x + rect.width / 2, rect.y + rect.height);
				break;
			case "top":
				this.pivot.set(rect.x + rect.width / 2, 0);
				break;
			case "left":
				this.pivot.set(rect.x, rect.y + rect.height / 2);
				break;
			case "right":
				this.pivot.set(rect.x + rect.width, rect.y + rect.height / 2);
				break;
		}
	}
}
