import { Room } from "colyseus.js";
import { IScene } from "@/scenes/IScene";
import { GameManager } from "@/managers/GameManager";

export abstract class ClientRoom<Scene extends IScene> {
	room: Room;
	scene: Scene;

	constructor(room: Room, scene: Scene) {
		this.room = room;
		this.scene = scene;

		this.room.removeAllListeners();

		// Setup callbacks
		this.setupMessageCallbacks();
		this.setupStateCallbacks();
		this.setupControlCallbacks();
	}

	protected abstract setupMessageCallbacks(): void;
	protected abstract setupStateCallbacks(): void;
	protected setupControlCallbacks() {
		this.room.onLeave((code) => this.onLeave(code));
		this.room.onError((code, message) => this.onError(code, message));
	}

	public leave(): void {
		this.room.leave();
	}

	// Control message delegates
	protected onLeave(code: number): void {
		console.log("Left room");
	}

	protected onError(code: number, message?: string): void {
		// Default error handling
		// -> Can be overridden in subclasses
		const msg = "An error ocurred in the room: " + message;
		console.error(msg);
		alert(msg);
		GameManager.returnToLobby();
	}
}
