import { GameManager } from "@/managers/GameManager";
import { SceneManager } from "@/managers/SceneManager";
import type { GameState } from "server/state/GameState";
import { PlayerId } from "server/state/PlayerState";
import { rotate, zip } from "server/utils/common";
import { ImprovedContainer, StatefulContainer } from "./containers/Containers";
import { DefuseCounter } from "./containers/DefuseCounter";
import { Loader } from "./containers/Loader";
import { Logo } from "./containers/Logo";
import { Location, Player } from "./containers/Player";
import { IScene } from "./IScene";

interface LayoutSlot {
	x: number;
	y: number;
	scale: number;
	location: Location;
}

function getLayout(playerCount: number, sceneManager: SceneManager): LayoutSlot[] {
	const onePlayerPerSide = {
		bottom: { x: sceneManager.width / 2, y: sceneManager.height, scale: 0.85, location: "bottom" },
		left: { x: 0, y: sceneManager.height / 2, scale: 0.85, location: "left" },
		top: { x: sceneManager.width / 2, y: 0, scale: 0.85, location: "top" },
		right: { x: sceneManager.width, y: sceneManager.height / 2, scale: 0.85, location: "right" },
	} as const;
	const twoPlayersPerSide = {
		bottom: [
			{ x: sceneManager.width * 0.33, y: sceneManager.height, scale: 0.7, location: "bottom" },
			{ x: sceneManager.width * 0.66, y: sceneManager.height, scale: 0.7, location: "bottom" },
		],
		left: [
			{ x: 0, y: sceneManager.height * 0.75, scale: 0.7, location: "left" },
			{ x: 0, y: sceneManager.height * 0.25, scale: 0.7, location: "left" },
		],
		// TODO fix broken top and right layout
		top: [
			{ x: sceneManager.width * 0.33, y: 0, scale: 0.7, location: "top" },
			{ x: sceneManager.width * 0.66, y: 0, scale: 0.7, location: "top" },
		],
		right: [
			{ x: sceneManager.width, y: sceneManager.height * 0.25, scale: 0.7, location: "right" },
			{ x: sceneManager.width, y: sceneManager.height * 0.75, scale: 0.7, location: "right" },
		],
	} as const;
	const defaultLayout = Object.values<LayoutSlot>(onePlayerPerSide);
	if (playerCount > 0 && playerCount <= 4) return defaultLayout;
	else if (playerCount === 5)
		return [...twoPlayersPerSide.bottom, onePlayerPerSide.left, onePlayerPerSide.top, onePlayerPerSide.right];
	else if (playerCount === 6)
		return [...twoPlayersPerSide.bottom, ...twoPlayersPerSide.left, onePlayerPerSide.top, onePlayerPerSide.right];
	else if (playerCount === 7)
		return [
			...twoPlayersPerSide.bottom,
			...twoPlayersPerSide.left,
			...twoPlayersPerSide.top,
			onePlayerPerSide.right,
		];
	else if (playerCount === 8)
		return [
			...twoPlayersPerSide.bottom,
			...twoPlayersPerSide.left,
			...twoPlayersPerSide.top,
			...twoPlayersPerSide.right,
		];
	else return [];
}

export class GameScene extends ImprovedContainer implements IScene, StatefulContainer<GameState> {
	players: Map<PlayerId, Player> = new Map();
	defuseCounter: DefuseCounter;
	logo: Logo;

	// Events
	onCardClick?: (cardId: number) => void;
	onCardHoverIn?: (cardId: number) => void;
	onCardHoverOut?: (cardId: number) => void;
	afterNextStateUpdate: (() => void)[] = [];

	protected _loader: Loader;
	protected readonly _innerPadding = 24;

	constructor() {
		super();

		// Init defuse counter
		this.defuseCounter = new DefuseCounter(0);
		this.defuseCounter.scale.set(1.2, 1.2);
		this.defuseCounter.position.set(
			GameManager.sceneManager.width - this.defuseCounter.width - this._innerPadding,
			GameManager.sceneManager.height - this.defuseCounter.height - this._innerPadding
		);

		// Init logo
		this.logo = new Logo(0.75);
		this.logo.position.set(
			GameManager.sceneManager.width / 2 - this.logo.width / 2,
			GameManager.sceneManager.height / 2 - this.logo.height / 2
		);

		// Init loader
		this._loader = new Loader();

		this.addChild(this.logo, this.defuseCounter, this._loader);
	}

	get localPlayer() {
		return this.players.get(GameManager.clientID ?? "");
	}

	setPlayers(state: GameState) {
		state.players.forEach((playerState, key) => {
			const player = new Player(playerState, key === GameManager.clientID);
			this.players.set(key, player);
			this.addChild(player);
		});
		this.updatePlayersLayout();
	}

	updateState(state: GameState) {
		const viewPlayers = [...this.players.values()];
		const statePlayers = [...state.players?.values()];
		// Update each player view based on their respective state
		zip(viewPlayers, statePlayers).forEach(([player, playerState]) => {
			// Safeguard in case there's not the same number of players and states
			if (playerState && player) {
				// Update view based on state
				player.updateState(playerState);
				// Update or attach click callback on each card
				player.hand.cards.forEach((card) => {
					card.onClick = this.onCardClick;
					card.onHoverIn = this.onCardHoverIn;
					card.onHoverOut = this.onCardHoverOut;
				});
			}
		});

		this.defuseCounter.updateState(state.defused);

		// Fire callbacks
		this.afterNextStateUpdate.forEach((callback) => callback());
		this.afterNextStateUpdate = [];
	}

	showLoader(message?: string | number) {
		this._loader.message = message;
		this._loader.start();
		this._loader.position.set(
			this.logo.x + this.logo.width / 2 - this._loader.width / 2,
			this.logo.y + this.logo.height + this._innerPadding
		);
	}

	hideLoader() {
		this._loader.stop();
	}

	showPlayerHand() {
		// Show hand once we received state from server
		this.afterNextStateUpdate.push(() => this.localPlayer?.hand.show());
	}

	hidePlayerHand() {
		// Hide hand once we received state from server (hopefully soon enough to keep delay between show/hide)
		this.afterNextStateUpdate.push(() => this.localPlayer?.hand.hide());
	}

	protected updatePlayersLayout() {
		const layout = getLayout(this.players.size, GameManager.sceneManager);
		if (layout) {
			let players = [...this.players.values()];
			// Rotate list to place local player at the first position
			// -> This ensures the local player is always at the bottom of the screen
			const idx = players.findIndex((player) => player === this.localPlayer);
			players = rotate(players, idx);
			// Place each player sequentially in layout, starting with local player
			players.forEach((player, idx) => {
				const slot = layout[idx];
				player.location = slot.location;
				player.scale.set(slot.scale, slot.scale);
				player.position.set(slot.x, slot.y);
				player.updateLayout();
			});
			// // DEBUG
			// const player = this.players.get(GameManager.clientID ?? "");
			// if (player) {
			// 	const slot = layout[1];
			// 	player.location = slot.location;
			// player.scale.set(slot.scale, slot.scale);
			// 	player.position.set(slot.x, slot.y);
			// 	player.updateLayout();
			// }
		}
	}

	update(framesPassed: number): void {}
}
