/**
 * Shuffle an array in-place.
 * @param arr the array to shuffle
 * @returns the shuffled array (the mutated array, not a copy)
 */
export function shuffle<T>(arr: T[]) {
	for (let i = arr.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[arr[i], arr[j]] = [arr[j], arr[i]];
	}
	return arr;
}

/**
 * Zip two arrays.
 * @param a the first array
 * @param b the second array
 * @param includeMissing whether to include empty values when a and b have not the same length
 * @returns a 2-dimension array where each first-level entry is a pair of a and b values
 */
export function zip<T, U>(a: T[], b: U[], includeMissing: boolean = false) {
	const lengths = [a.length, b.length];
	const bound = includeMissing ? Math.max(...lengths) : Math.min(...lengths);
	const zipped: [T | undefined, U | undefined][] = [];
	for (let i = 0; i < bound; i++) {
		zipped.push([a[i] ?? undefined, b[i] ?? undefined]);
	}
	return zipped;
}

/**
 * Rotate the values of an array by a specified amount.
 * @param arr the array to rotate
 * @param count the rotation step. A positive step rotates towards the left, a negative one towards the right.
 * @returns a copy of the original array after rotation
 */
export function rotate<T>(arr: T[], count: number = 1) {
	return [...arr.slice(count, arr.length), ...arr.slice(0, count)];
}

/**
 * Truncate a string if its longer than the specified length.
 * @param str the string to truncate
 * @param length the length above which the string is truncated
 * @param ellipsis the string to use as ellipsis on a truncated string
 * @returns the string truncated to length if its original length is above the threshold
 */
export function truncateString(str: string, length: number, ellipsis: string = "...") {
	return str?.length > length ? str.slice(0, length) + ellipsis : str;
}
