import { Easing, Tween, update as updateTween } from "@tweenjs/tween.js";
import { Sprite, Ticker } from "pixi.js";
import { ImprovedContainer, StatefulContainer } from "./Containers";
import { Player } from "./Player";

export class WireCutter extends ImprovedContainer implements StatefulContainer<boolean> {
	sprite: Sprite;

	protected _started: boolean = false;
	protected _speed: number = 0;
	protected _runningAnim?: Tween<any>;

	constructor(state: boolean) {
		super();
		this.sprite = Sprite.from("wire-cutter");
		this.sprite.scale.set(0.75);
		this.sprite.visible = false;
		this.addChild(this.sprite);
		this.updateState(state);

		Ticker.shared.add(() => updateTween());
	}

	updateState(state: boolean): void {
		state ? this.moveIn() : this.moveOut();
	}

	protected moveIn() {
		if (this._runningAnim?.isPlaying) this._runningAnim.stop();
		this._runningAnim = new Tween(this.sprite.position)
			.to({ x: 0 }, 1000)
			.easing(Easing.Elastic.InOut)
			.onStart(() => (this.sprite.visible = true))
			.onUpdate(() => (this.parent as Player).updateLayout())
			.onComplete(() => (this._runningAnim = undefined))
			.start();
	}

	protected moveOut() {
		if (this._runningAnim?.isPlaying) this._runningAnim.stop();
		this._runningAnim = new Tween(this.sprite.position)
			.to({ x: this.sprite.width }, 1000)
			.easing(Easing.Elastic.InOut)
			.onUpdate(() => (this.parent as Player).updateLayout())
			.onComplete(() => {
				this.sprite.visible = false;
				this._runningAnim = undefined;
			})
			.start();
	}
}
